import React, { useState, useContext, useRef, useEffect } from 'react';
import { MenuContext } from "../../contexts/menu-context";
import "./contact-form.scss";
import emailjs from 'emailjs-com';

emailjs.init("5DZhd8mOKtwaFlfH0");

const ContactForm = () => {
  const { isMenuShown } = useContext(MenuContext);
  const divRef = useRef();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  function gtag_report_conversion(url) {
    if (window.gtag) {
      var callback = function () {
        if (typeof(url) !== 'undefined') {
          window.location = url;
        }
      };
      window.gtag('event', 'conversion', {
          'send_to': 'AW-16562948981/NHdmCIDd_a4ZEPWW6tk9',
          'value': 1.0,
          'currency': 'USD',
          'event_callback': callback
      });
    }
  }
  
  
  const sendEmail = (e) => {
    e.preventDefault(); // Prevent the actual form submission
    emailjs.sendForm('service_o5q4vga', 'template_dp8abks', e.target)
      .then((result) => {
        console.log('Email sent:', result.text);
        gtag_report_conversion();
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 5000); // Hide alert after 5 seconds
      }, (error) => {
        console.log('Failed to send email:', error.text);
        setShowErrorMessage(true);
        setTimeout(() => setShowErrorMessage(false), 5000); // Hide error alert after 5 seconds
      });
  }

  useEffect(() => {
    if (isMenuShown) {
      divRef.current.classList.add("darken");
    } else {
      divRef.current.classList.remove("darken");
    }
  }, [isMenuShown]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    try {
      sendEmail(e);
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  return (
    <div className="contact" ref={divRef}>
      {showSuccessMessage && (
        <div className="success-alert">
          Your message was sent and a team member will be in contact shortly.
        </div>
      )}
            {showErrorMessage && (
        <div className="alert error-alert">
          There was an error sending your message. Please try again.
        </div>
      )}
      <form onSubmit={handleSubmit} className="contact__container">
        <div className="contact__container__title">
          <h1>Contact Us</h1>
        </div>

        <div className="contact__container__inputs">
          <label htmlFor="name">Your Name</label>
          <input id="name" name="name" type="text" placeholder="Enter your name" value={formData.name} onChange={handleChange} />

          <label htmlFor="email">Email Address</label>
          <input id="email" name="email" type="email" placeholder="Enter your email address" value={formData.email} onChange={handleChange} />

          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" placeholder="Type your message here" value={formData.message} onChange={handleChange}></textarea>
        </div>

        <div className="contact__container__btn">
          <button type="submit" className="btn-primary">Send Message</button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
