import React from "react";
// import AuthForm from "../components/auth/AuthForm";
import ContactForm from "../components/contact/ContactForm";

const Auth = () => {
  // return <AuthForm />;
  return <ContactForm />
};

export default Auth;
